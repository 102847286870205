import React from 'react'
import Sebrae from '../assets/imgs/sebrae.png'

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="header">
          <div className="titulo">
            <img src={Sebrae} alt="Logo da Sebrae" />
            <h2>
              Uma Parceria <br />
              <strong>
                com soluções para <br />
                sua empresa
              </strong>
            </h2>
          </div>
          <div className="descricao">
            <p>
              O Sebrae está oferecendo 1 capacitação online{' '}
              <strong>GRATUITA</strong> e 1 consultoria online aos
              empreendedores interessados.
            </p>
            <p>
              Mesmo sem efetivação do crédito, você pode ter acesso a
              ferramentas que te ajudam a gerenciar melhor seus negócios.
            </p>
            <p>
              <strong>Aproveite essa oportunidade!</strong>
            </p>
          </div>
        </div>

        <div className="content">
          <div className="col">
            <h3>CONSULTORIA EM GESTÃO</h3>
            <a
              className="btn"
              href="https://sebraers.com.br/destaque/consultoria-em-gestao/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acesse aqui
            </a>

            <h3>CURSO FINANÇAS E ATENDIMENTO</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/financas/curso/programa-cred-portao"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acesse aqui
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionD
