let agencias = [
    {
        id: 1743,
        name: 'Portão Centro',
    },
    {
        id: 4459,
        name: 'Avenida Brasil',
    },
]

export {agencias}